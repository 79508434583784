<template>
  <b-card no-body class="mb-0">

    <b-card-header class="mb-1 p-1" header-bg-variant="light-primary">
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar
              ref="previewEl"
              :src="(generalData.profilePictureBase64 ? generalData.profilePictureBase64 : getApiFile(generalData.image_url))"
              :text="avatarText(generalData.name + ' ' + generalData.lastname)"
              button
              variant="primary"
              size="50px"
              @click="$refs.refInputEl.click()"
              badge
              class="badge-minimal"
              badge-variant="success"/>
          <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer">
        </b-media-aside>
        <b-media-body>
          <h5 class="mb-0">
            {{ generalData.name + ' ' + generalData.lastname }}
          </h5>
          <small class="text-muted">{{ generalData.email }}</small>

          <div class="my-auto">
            <b-badge variant="light-primary">{{ generalData.title }}</b-badge>
          </div>

        </b-media-body>
      </b-media>
    </b-card-header>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
        <b-row>
          <b-col cols="12">
            <validation-provider #default="validationContext" name="Name" rules="required">
              <b-form-group label="Name">
                <b-form-input trim placeholder="Name" v-model="generalData.name" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider #default="validationContext" name="LastName" rules="required">
              <b-form-group label="LastName">
                <b-form-input trim placeholder="LastName" v-model="generalData.lastname" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider #default="validationContext" name="Phone">
              <b-form-group label="Phone">
                <b-form-input trim placeholder="Phone" v-model="generalData.phone" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider #default="validationContext" name="Title">
              <b-form-group label="Title">
                <b-form-input trim placeholder="Title" v-model="generalData.title" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>
        <action-buttons :back-route="'home'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BFormInvalidFeedback, BAvatar, BCardHeader, BCardTitle, BBadge,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {onUnmounted, ref} from '@vue/composition-api'
import {email, required} from "@core/utils/validations/validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import {avatarText, getApiFile} from "@core/utils/filter"
import ActionButtons from "@/components/Form/ActionButtons.vue";
import Overlay from "@/components/Overlay.vue";
import {useToast} from "vue-toastification/composition";
import store from "@/store";
import storeModule from "@/views/common/account/store";
import {toastMessage} from "@core/utils/utils";

export default {
  components: {
    Overlay,
    ActionButtons,
    BBadge,
    BCardTitle,
    BCardHeader,
    BAvatar,
    BFormInvalidFeedback,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
    }
  },
  props: {
    generalData: {
      type: Object,
      default: () => {
      },
    },
  },
  setup(props) {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const previewEl = ref(null)
    const refInputEl = ref(null)
    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      props.generalData.profilePictureBase64 = base64
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const toast = useToast()
    const busy = ref(false)

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/editItem', props.generalData).then(response => {
        const user = JSON.parse(localStorage.getItem('userData'))
        user.email = response.data.data.email
        user.phoneNumber = response.data.data.phoneNumber
        user.name = response.data.data.firstName + ' ' + response.data.data.lastName
        user.profilePicture = response.data.data.profilePicture
        localStorage.setItem('userData', JSON.stringify(user))
        toastMessage(toast, 'success', response.data.message)
        setTimeout(function () {
          window.location.reload()
        }, 2000);
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    return {
      refFormObserver,
      previewEl,
      refInputEl,
      busy,

      onSubmit,
      getApiFile,
      avatarText,
      getValidationState,
      resetForm,
      inputImageRenderer,
    }
  },
}
</script>
